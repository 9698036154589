export const FeaturedStoriesNavbarData = [
   
    {
        Stories: 'ALL',
    },
    {
        Stories: 'BUSINESS',
    },
    {
        Stories: 'ENTERTAINMENT',
    },
    {
        Stories: 'HEALTH',
    },
    {
        Stories: 'LIFESTYLE',
    }
]