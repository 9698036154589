export const TopNewsData = [
    {
        Category: 'sport',
        Level: 'Top',
        Title: 'BUSINESS',
        Headlines: 'Google, Facebook Extend Work From Home Policies Until 2021',
        Author: 'MC CADMAN',
        Time: 'MAY 18, 2023',
        Photo: 'https://jnews.io/bloomnews/wp-content/uploads/sites/75/2020/01/16-350x250.jpg',
        Content: 'Strech lining hemline above knee burgundy glossy silk complete hid zip little catches rayon. Tunic weaved strech calfskin spaghetti straps...',
    },
    {
        Category: 'sport',
        Level: 'Mid',
        Title: '',
        Headlines: 'These Foods to Absolutely Avoid If You Want Clear, Glowing Skin',
        Author: '',
        Time: 'MAY 26, 2020',
        Photo: 'https://jnews.io/bloomnews/wp-content/uploads/sites/75/2020/02/37-750x375.jpg',
        Content: 'Strech lining hemline above knee burgundy glossy silk complete hid zip little catches rayon. Tunic weaved strech calfskin spaghetti straps...',
    },
    {
        Category: 'business',
        Level: 'Mid',
        Title: '',
        Headlines: 'Will It Be Safe to Travel This Summer? Here’s Your Options',
        Author: '',
        Time: 'MAY 18, 2020',
        Photo: 'https://jnews.io/bloomnews/wp-content/uploads/sites/75/2020/02/07-750x375.jpg',
        Content: 'Strech lining hemline above knee burgundy glossy silk complete hid zip little catches rayon. Tunic weaved strech calfskin spaghetti straps...',
    },
    {
        Category: 'business',
        Level: 'Mid',
        Title: '',
        Headlines: 'Cleaners Vs. Disinfectants: What You Need to Know',
        Author: '',
        Time: 'MAY 26, 2020',
        Photo: 'https://jnews.io/bloomnews/wp-content/uploads/sites/75/2020/01/11-750x375.jpg',
        Content: 'Strech lining hemline above knee burgundy glossy silk complete hid zip little catches rayon. Tunic weaved strech calfskin spaghetti straps...',
    },
    {
        Category: 'lifestyle',
        Level: 'Mid',
        Title: '',
        Headlines: 'Fashion Stories From Around The Web You Might Have Missed',
        Author: '',
        Time: 'MAY 26, 2020',
        Photo: 'https://jnews.io/bloomnews/wp-content/uploads/sites/75/2020/02/08-750x375.jpg',
        Content: 'Strech lining hemline above knee burgundy glossy silk complete hid zip little catches rayon. Tunic weaved strech calfskin spaghetti straps...',
    }
]