export const CategoriesData = [
    {
        CategoryName: 'BUSINESS',
        CategoryNumber: '12',
    },
    {
        CategoryName: 'ENTERTAINMENT',
        CategoryNumber: '13',
    },
    {
        CategoryName: 'FASHION',
        CategoryNumber: '10',
    },
    {
        CategoryName: 'FOOD',
        CategoryNumber: '8',
    },
    {
        CategoryName: 'HEALTH',
        CategoryNumber: '9',
    },
    {
        CategoryName: 'LIFESTYLE',
        CategoryNumber: '21',
    },
    {
        CategoryName: 'SPORTS',
        CategoryNumber: '10',
    },
    {
        CategoryName: 'TRAVEL',
        CategoryNumber: '15',
    },
    {
        CategoryName: 'WORLD',
        CategoryNumber: '13',
    },
]